@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.cta {
  padding: 0 1rem;
  padding-bottom: 2.5rem;

  @include breakpoint-min-size($tablet) {
    @include flex-row;
    align-items: center;
    justify-content: center;
  }

  @include breakpoint-min-size($desktop) {
    padding: 4rem 0rem;
  }

  &__container {
    @include breakpoint-min-size($tablet) {
      width: 70%;
    }

    @include breakpoint-min-size($desktop) {
      width: 60%;
    }
  }

  &__header {
    @include m-bold-heading-large;
    font-weight: 700;
    margin-block-end: 0.8rem;
  }

  &__desc {
    @include m-body-serif;
    margin-block-start: 0;
    max-width: 100%;

    @include breakpoint-min-size($desktop) {
      width: 70%;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 16rem;
  }

  &__item {
    @include m-text-link;
    text-decoration: none;

    &:hover {
      background-color: $tan-background;
    }
  }
}
