@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.page__container {
  position: relative;
  z-index: 5;
  margin-bottom: 8rem;
  background-color: $white;
}

.projects-box {
  padding: 1.5rem 1.5rem;

  @include breakpoint-min-size($tablet) {
    @include flex-row;
    justify-content: center;
  }

  &__container {
    width: 100%;

    @include breakpoint-min-size($tablet) {
      width: 70%;
    }

    @include breakpoint-min-size($desktop) {
      width: 60%;
    }
  }

  &__link {
    text-decoration: none;
  }
}
