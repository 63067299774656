@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.nav__links > a {
  text-decoration: none;

  &:hover {
    background-color: $tan-background;
  }
}

.nav {
  @include flex-row;
  justify-content: center;

  &__container {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
    width: 100%;

    @include breakpoint-min-size($tablet) {
      width: 69%;
    }

    @include breakpoint-min-size($desktop) {
      width: 60%;
    }
  }

  &__links {
    display: none;
    @include breakpoint-min-size($tablet) {
      gap: 1rem;
      @include flex-row;
    }

    &-item {
      @include m-body;

      font-size: 1.1rem;
    }
  }

  &__mobile-menu {
    @include flex-row;
    @include breakpoint-min-size($tablet) {
      display: none;
    }
  }

  &__logo-img {
    width: 60%;
  }
}

.mobile-menu {
  width: 2rem;
}

.mobile-nav {
  width: 100%;
  height: fit-content;
  background-color: #f5f0ec;
  margin-bottom: 2rem;
  display: block;

  @include breakpoint-min-size($desktop) {
    display: none;
  }

  &__box {
    @include flex-column;
  }

  &__link {
    @include m-body;
    font-weight: 600;
    text-decoration: none;
    padding: 1.1rem;
    background-color: #f5f0ec;

    &:hover {
      background-color: $blue;
      color: white;
    }
  }
}
