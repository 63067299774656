@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.activity {
  @include breakpoint-min-size($desktop) {
    width: 45%;
  }

  &__title {
    @include m-body-serif;
  }

  &__desc {
    @include m-body;
  }
}
