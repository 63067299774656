@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.project {
  @include breakpoint-min-size($desktop) {
    margin-bottom: 3rem;
    max-width: 100%;
  }

  &__img-container {
    height: 12rem;
    width: 100%;
    border-radius: 8px;
    @include flex-row;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;

    @include breakpoint-min-size($desktop) {
      height: 16rem;
    }

    @include breakpoint-min-size(1600px) {
      height: 20rem;
    }
  }

  &__group-img {
    width: 73%;
  }

  &__info {
    padding-top: 0.6rem;
    padding-bottom: 2rem;

    @include breakpoint-min-size($desktop) {
      justify-content: space-between;
      max-width: 100%;
      align-items: flex-start;
    }
  }

  &__logo {
    width: 70%;
  }

  &__title {
    @include m-serif-lg;
    @include removeMargin;

    @include breakpoint-min-size($desktop) {
      font-size: 2rem;
      width: 85%;
      padding-bottom: 0.5rem;
    }
  }

  &__desc {
    @include removeMargin;
    @include m-body;
    padding-top: 0.4rem;

    @include breakpoint-min-size($desktop) {
      font-size: 1.1rem;
    }
  }
}
