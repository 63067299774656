@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.footer {
  padding-bottom: 2rem;
  background-color: #e1e6f3;
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: 4rem;
  @include breakpoint-min-size($tablet) {
    @include flex-row;
    justify-content: center;
  }

  @include breakpoint-min-size($desktop) {
    padding-bottom: 4rem;
  }

  &__container {
    @include breakpoint-min-size($tablet) {
      @include flex-row;
      align-items: center;
      justify-content: space-between;
      width: 69%;
    }

    @include breakpoint-min-size($desktop) {
      width: 60%;
    }
  }

  &__copyright {
    @include m-body-serif;
    padding-bottom: 0.6rem;
    margin-block-end: 0;
  }

  &__link {
    @include m-text-link;

    @include breakpoint-min-size($desktop) {
      font-size: 1.1rem;
    }

    &:hover {
      background-color: #f5f0ec;
    }
  }

  &__left {
    padding-left: 1.5rem;

    @include breakpoint-min-size($tablet) {
      padding-left: 0;
    }
  }

  &__right {
    @include flex-row;
    gap: 0.5rem;
    padding-left: 1.5rem;
    @include breakpoint-min-size($desktop) {
      align-items: center;
    }
  }
}

.theme-button {
  display: flex;
  @include centered;
  height: 2rem;
  width: 6rem;
  background-color: red;
}
