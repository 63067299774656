@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.top-paragraph {
  @include breakpoint-min-size($tablet) {
    @include flex-row;
    justify-content: center;
    padding: 2em 0rem;
  }

  &__container {
    margin: 1rem 0;
    width: 90%;
    @include breakpoint-min-size($tablet) {
      width: 70%;
    }

    @include breakpoint-min-size($desktop) {
      width: 61%;
    }
  }
}

.big {
  &__container {
    @include flex-row;
    align-items: center;
    gap: 0.5rem;
  }

  &__title {
    @include m-h1-style;
    @include removeMargin;
    padding-left: 1rem;

    @include breakpoint-min-size($desktop) {
      font-size: 2.5rem;
    }
  }

  &__desc {
    @include removeMargin;
    @include m-serif-lg;
    padding: 1rem;

    @include breakpoint-min-size($desktop) {
      font-size: 1.7rem;
      max-width: 86%;
    }
  }

  &__sparkle-container {
    height: 100%;
  }
}

.sparkle-group {
  height: 2rem;
  padding-top: 0.2rem;
  display: none;

  @include breakpoint-min-size($tablet) {
    display: block;
  }
}

.list {
  &__container {
    padding-left: 1rem;

    @include breakpoint-min-size($desktop) {
      @include flex-row;
      gap: 0.6rem;
    }
  }

  &__desc {
    @include removeMargin;
    @include m-body;

    @include breakpoint-min-size($desktop) {
      font-size: 1.1rem;
    }
  }

  &__item {
    @include flex-row;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__inline {
    font-weight: 600;
    color: $blue;
    padding-left: 0.3rem;

    &:hover {
      background-color: $tan-background;
    }
  }
}
