@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.page__container {
  position: relative;
  z-index: 5;
  margin-bottom: 8rem;
  background-color: $white;
}

.about {
  &__top {
    @include flex-row;
    justify-content: center;
    padding-bottom: 2.5rem;

    @include breakpoint-min-size($tablet) {
      padding-bottom: 0rem;
    }

    @include breakpoint-min-size($desktop) {
      margin-bottom: 2rem;
    }

    &-container {
      @include flex-column;
      padding: 1.5rem;

      @include breakpoint-min-size($tablet) {
        width: 70%;
        @include flex-row;
        @include centered;
        gap: 3rem;
      }

      @include breakpoint-min-size($desktop) {
        width: 60%;
      }
    }

    &-content {
      order: 1;

      @include breakpoint-min-size($desktop) {
        order: 2;
      }
    }
  }

  &__photo {
    order: 2;
    width: 100%;
    max-width: 20rem;

    @include breakpoint-min-size($tablet) {
      height: 13rem;
    }

    @include breakpoint-min-size($desktop) {
      height: 13rem;
      order: 1;
    }
  }

  &__header {
    @include m-h1-style;
    margin-block-end: 0;
    padding-bottom: 0.5rem;
  }

  &__desc {
    @include m-serif-lg;
    margin-block-start: 0;

    @include breakpoint-min-size($desktop) {
      width: 100%;
    }
  }

  &__h2 {
    @include m-bold-heading-large;
    @include removeMargin;
    @include breakpoint-min-size($desktop) {
      padding-left: 0;
    }
  }
}

.work {
  &__header {
    @include m-body-serif;
    margin-block-end: 0;
  }

  &__list {
    padding-inline-start: 0;
    margin-block-start: 0.5rem;

    &-item {
      list-style: none;
      padding-bottom: 0.5rem;
      @include m-body;

      @include breakpoint-min-size($desktop) {
      }
    }
  }
}

.professional-stuff {
  @include flex-row;
  justify-content: left;
  padding-bottom: 2.5rem;

  @include breakpoint-min-size($tablet) {
    padding-bottom: 0;
    justify-content: center;
  }

  &__box {
    padding: 0 1rem;
    @include flex-column;

    @include breakpoint-min-size($tablet) {
      @include flex-row;
      justify-content: space-between;
      padding: 2rem 0;
      width: 70%;
      gap: 2rem;
    }

    @include breakpoint-min-size($desktop) {
      width: 60%;
    }
  }
}

.resume-things {
  @include flex-column;
  max-width: 100%;

  @include breakpoint-min-size($tablet) {
    max-width: 60%;
  }
}

.after-hours {
  padding: 0 1rem;
  padding-bottom: 2.5rem;

  @include breakpoint-min-size($tablet) {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
  }

  &__container {
    @include breakpoint-min-size($tablet) {
      width: 70%;
    }

    @include breakpoint-min-size($desktop) {
      width: 61%;
    }
  }

  &__titles {
    @include breakpoint-min-size($desktop) {
      @include flex-row;
      align-items: flex-end;
      width: 66%;
      justify-content: flex-start;
      gap: 3rem;
    }
  }

  &__grid {
    @include breakpoint-min-size($desktop) {
      @include flex-row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.5rem;
    }
  }

  &__header {
    @include m-bold-heading-large;
    margin-block-end: 0;
  }

  &__subheader {
    @include m-body-serif;
    @include removeMargin;
  }
}
