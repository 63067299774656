@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.hackathon-item {
  @include flex-row;
  justify-content: space-between;
  height: 2rem;
  align-items: center;
  cursor: pointer;

  &__img-box {
    position: fixed;
    top: 0;
    left: 0;
    height: 12rem;
  }

  &__img {
    height: 100%;
  }

  &__main {
    @include flex-row;
    gap: 0.5rem;
    height: fit-content;
  }

  &__name {
    @include removeMargin;
    @include m-body;
    padding-bottom: 0.25rem;
  }

  &__icon {
    height: 1.25rem;
  }

  &__date {
    @include m-body;
    @include removeMargin;
    width: 10%;
  }
}

.hackathon-item > .hackathon-item__img-box {
  display: none;
}

.hackathon-item:hover > .hackathon-item__img-box {
  display: block;
}
