@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.summary-box {
  @include flex-row;
  justify-content: center;
  padding: 0 1rem;
  margin: 1rem 0;
  padding-bottom: 2.5rem;

  @include breakpoint-min-size($tablet) {
    padding-bottom: 1rem;
  }

  @include breakpoint-min-size($desktop) {
    margin-bottom: 2rem;
    padding: 0;
  }

  &__container {
    border: solid 4px #4069cc;
    border-radius: 16px;
    padding: 0rem 2rem;

    @include breakpoint-min-size($tablet) {
      width: 70%;
    }

    @include breakpoint-min-size($desktop) {
      width: 60%;
      padding: 2rem 0;
    }
  }

  &__items {
    @include flex-column;
    @include breakpoint-min-size($desktop) {
      @include flex-row;
      justify-content: space-evenly;
      padding: 0.25rem;
    }
  }

  &__title {
    @include m-bold-heading-large;
    @include removeMargin;
    background-color: white;
    position: relative;
    padding: 0 1rem;
    top: -20px;
    left: -12px;

    max-width: fit-content;

    @include breakpoint-min-size($desktop) {
      top: -48px;
      left: 60px;
    }
  }
}
