@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.p {
  &__header {
    width: 100%;
    height: 100%;
    @include flex-column;

    @include breakpoint-min-size($desktop) {
    }
  }

  &__arrow {
    height: 3.5rem;
    margin-left: 1rem;

    &:hover {
      filter: invert(38%) sepia(70%) saturate(680%) hue-rotate(186deg)
        brightness(92%) contrast(91%);
    }

    &-div {
      width: 100%;
      padding-top: 1rem;
    }
  }

  &__logo {
    width: 60%;
    &-div {
      width: 100%;
      @include flex-row;
      align-items: baseline;
      justify-content: center;
      height: 100%;
    }
  }
}
