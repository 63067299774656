@import "../../styles/partials/_Variables";

// FLEXBOX
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

// MEDIA QUERIES
@mixin breakpoint-min-size($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-range($breakpoint1, $breakpoint2) {
  @media (min-width: $breakpoint1) and (max-width: $breakpoint2) {
    @content;
  }
}

@mixin breakpoint-max-size($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

// FONT STYLES - MOBILE

@mixin m-nav-links {
  font-family: "Fraunces";
  font-weight: 600;
  font-size: 1.2rem;
  color: $blue;
  text-decoration: none;
}

@mixin m-h1-style {
  font-family: "Fraunces", sans-serif;
  font-size: 1.7rem;
  font-weight: 600;
  color: $black;
}

@mixin m-serif-lg {
  font-family: "Fraunces", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: $black;
}

@mixin m-bold-story-heading {
  font-family: "Fraunces";
  font-weight: 600;
  font-size: 2rem;
  color: $black;
}

@mixin m-bold-heading-large {
  font-family: "Fraunces";
  font-weight: 700;
  font-size: 1.6rem;
  color: $black;
}

@mixin m-bold-heading {
  font-family: "Fraunces";
  font-weight: 600;
  font-size: 1.6rem;
  color: $black;
}

@mixin m-captions {
  font-family: "Uncut Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: #5e5e5e;
}

@mixin m-body {
  font-family: "Uncut Sans";
  font-weight: 500;
  font-size: 1rem;
  color: $black;
}

@mixin m-body-serif {
  font-family: "Fraunces";
  font-weight: 600;
  font-size: 1.2rem;
  color: $black;
}

@mixin m-text-link {
  font-family: "Uncut Sans";
  font-weight: 600;
  font-size: 1rem;
  color: $blue;
}

@mixin m-divider {
  font-family: "Uncut Sans", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: $blue;
}

// COMMON STUFF
@mixin centered {
  justify-content: center;
  align-items: center;
}

@mixin removeMargin {
  margin-block-end: 0;
  margin-block-start: 0;
}
