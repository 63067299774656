// BREAKPOINTS
$mobile: 320px;
$tablet: 768px;
$desktop: 1280px;
$xl-desktop: 1400px;

// COLORS
$black: #1e1800;
$blue: #4069cc;
$blue-highlight: #d3eaff;
$tan-background: #f5f0ec;
$white: #fafafa;
