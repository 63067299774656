@import "../../styles/partials/_FontStyles";
@import "../../styles/partials/_Mixins";
@import "../../styles/partials/_Variables";

.item {
  padding-bottom: 1.5rem;

  @include breakpoint-min-size($desktop) {
    max-width: 24%;
  }

  &__title {
    @include removeMargin;
    @include m-body-serif;

    &-box {
      @include flex-row;
      gap: 0.6rem;
      padding-bottom: 0.5rem;
      align-items: center;
    }
  }

  &__desc {
    @include m-body;
    @include removeMargin;
  }
}
